import React, { useState, useEffect, useRef, useCallback } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { ShareRequestResultsTimeline } from "./ShareRequestResultsTimeline";
import { ShareRequestResultsSummary } from "./ShareRequestResultsSummary";
import { redirectToShareResultCheckout } from "./../util/stripe";
import { ShareRequestDemoLive } from "./ShareRequestDemoLive"; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useShareRequestsAcceptedList } from "../util/db";
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';



const moment = require('moment');

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;

const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    button: {
      background: '#000000',
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

export const ShareRequestLiveResults = (props) => {
    const classes = useStyles();
   
    const detailRef = useRef();


    const [data, setData] = useState([]);
    const [immersionDataPoints, setImmersionDataPoints] = useState([]);
    const [safetyDataPoints, setSafetyDataPoints] = useState([]);
    const [downloadData, setDownloadData] = useState([]);

    const [experienceAccountIds, setExperienceAccountIds] = useState([]);
    

    const [hasData, setHasData] = useState(false);

    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [duration, setDuration] = useState();

    const [immersionOverall, setImmersionOverall] = useState(0);
    const [safetyOverall, setSafetyOverall] = useState(0);
    const [shape, setShape] = useState('flat');

    const [nobuyopen, setNobuyopen] = useState(false);
    
    const [completeCount, setCompleteCount] = useState(0);

    const { team, shareRequest, legend, isPaid, downloadCallback, scoreCallback, ...otherProps } = props;
    //TODO: replace the group id with an array of users
    //const groupId = "tues";
    var aggWindow = "1m";
   

    const { data: shareAccepts, status: resultsStatus, error: resultsError, } = useShareRequestsAcceptedList(shareRequest.id);


  // console.log(shareRequest);

   //console.log(shareAccepts);
   

    useEffect(() => {
        if (shareRequest) {
            //console.log(shareRequest);
            let sr = getMomentFromTimestamp(shareRequest.start).toISOString();
            setStartRange(sr);
            let er = getMomentFromTimestamp(shareRequest.end).toISOString();
            setEndRange(er);
            let du = moment.duration(getMomentFromTimestamp(shareRequest.start).diff(getMomentFromTimestamp(shareRequest.end)));
            setDuration(du);

            if (du.asHours() >= 8) {
              aggWindow = "30m";
            }

           // console.log(duration);

            
        }
      }, []);

     
      //const usersByLikes = myUsers.map(item => {

      useEffect(() => {
        if (shareAccepts) {
          //console.log("shareAccepts!!!!");

          const accountIds = shareAccepts.map(item => { return item.account_id; });
          setCompleteCount(accountIds.length);
          setExperienceAccountIds(accountIds);
          //console.log(accountIds);
          let du = moment.duration(getMomentFromTimestamp(shareRequest.start).diff(getMomentFromTimestamp(shareRequest.end)));
          if (du.asHours() >= 8) {
            aggWindow = "30m";
            loadMinuteData(accountIds, startRange, endRange, "30m");
          } else {
            aggWindow = "1m";
            loadMinuteData(accountIds, startRange, endRange, "1m");
          }

        }

      }, [shareAccepts]);


      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

      const downloadDetailSection = useCallback(() => {
        handleDownloadImage(detailRef.current, 'SIX_detail');
      }, [shareAccepts]);

      const buy = (quantity) => {
        if (quantity >= 1) {
          redirectToShareResultCheckout(shareRequest.id, shareRequest.owner, "pr_tuesday_sr_10", quantity).catch((error) => {
            console.log(error.message);
          });
        } else {
          setNobuyopen(true);
        }
    
    }

    const handleClose = () => {
      setNobuyopen(false);
    };

    const averageArray = (nums) => {
        return nums.reduce((a, b) => (a + b)) / nums.length;
    }


    const getMomentFromTimestamp = (timestampObject) => {
        const timestamp = new Timestamp(timestampObject.seconds, timestampObject.nanoseconds);
    
        return moment(new Date(timestamp.toDate()))
    }

    const arrayToQueryString = (arr) => {
      var r = "[\"";
      let authorString = arr.join("\",\"");
      //console.log(authorString);
      const x = r + authorString + "\"]";
      //console.log(x);
      return x;
    }



    const changeView = (newAgg) => {
      aggWindow = newAgg;
      loadMinuteData(experienceAccountIds, startRange, endRange, newAgg);
    }


    const loadMinuteData = (accountIds, start, end, aggregationWindow) => {
        //console.log("loadMinuteData");

        let localQuery = `data = from(bucket: "${bucket}")
            |> range(start: ${start}, stop: ${end})
            |> filter(fn: (r) => r["_measurement"] == "score")
            |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
            |> filter(fn: (r) => contains(value: r["pid"], set: ${arrayToQueryString(accountIds)}))
            |> aggregateWindow(every: ${aggregationWindow}, fn: mean, createEmpty: true)
            |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
            |> group(columns: ["_time"], mode: "by")
            
            mean_e = data
	            |> mean(column: "e")

            mean_s = data
              |> mean(column: "s")

            join(tables: {t1: mean_e, t2: mean_s}, on: ["_time"])`;

            //console.log("localQuery = " + localQuery);

        let res = [];
        const influxQuery = async () => {
          //create InfluxDB client
          const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
          //console.log(queryApi);
    
          //make query
          await queryApi.queryRows(localQuery, {
            next(row, tableMeta) {
             //  console.log("a");
              const o = tableMeta.toObject(row);
             //push rows from query into an array object
              res.push(o);
            },
            complete() {
              
    
              let tmpDownloadDataset = [] 
    
              let finalData = []
              let engagement = []
              let safety = []

              let engagementPoints = []
              let safetyPoints = []


              //console.log(res);
              if (res.length > 0) {
                setHasData(true);
              } else {
                setHasData(false);
              }

              for(let i = 0; i < res.length; i++) {
                
                
                const e = res[i]['e'] !== undefined ? res[i]['e'] : 0.0;
                const s = res[i]['s'] !== undefined ? res[i]['s'] : 0.0;

                engagementPoints.push(Math.round(e));
                safetyPoints.push(Math.round(s));

                engagement.push({ "x": res[i]['_time'], "y" : Math.round(e) });
                safety.push({ "x" : res[i]['_time'], "y" : Math.round(s) });
    
                tmpDownloadDataset.push({"date": res[i]['_time'], "value": Math.round(e), "safety": Math.round(s)});
    
                
              }
    


              finalData.push({"id": "Value Score", "data" : engagement});
              finalData.push({"id": "Safety", "data" : safety});
    
             // console.log(finalData);
    
              setData(finalData);
              setImmersionDataPoints(engagementPoints);
              setSafetyDataPoints(safetyPoints);

              let expShape = calculateShape(engagementPoints);


              let is = Math.round(averageArray(engagementPoints));
              let ss = Math.round(averageArray(safetyPoints));
              setImmersionOverall(is);
              setSafetyOverall(ss);

             // console.log(engagementPoints);
             // console.log(safetyPoints);

              setDownloadData(tmpDownloadDataset);
              downloadCallback(tmpDownloadDataset);
              scoreCallback(is, ss, expShape);



            },
            error(error) {
              console.log("query failed- ", error);
            }
          });
         
        };
    
        influxQuery();
    
      }


      const calculateShape = (experiencedata) => {
          var myshape = "flat";
//set shape
if (experiencedata && experiencedata.length > 0) {
  const data = experiencedata.map(score => {
    return {immersion: score}
})
  const totalCount = data.length;
  const halfCount = Math.floor(totalCount/2);
  //console.log(totalCount);
  //console.log(halfCount);

  let t = 0;
  let tScores = 0;
  for(let i = 0; i < data.length; i++) {
      t += i;
      tScores += data[i].immersion;
  }

  const averageAllTimes = (data.length/2)/2;
  const averageAllScores = tScores / data.length;
  console.log(`Averages time: ${t} ${averageAllTimes}  scores: ${averageAllScores}`);

  let partOneASum = 0;
  let partOneBSum = 0;
  let partTwoASum = 0;
  let partTwoBSum = 0;

  for(let i = 0; i < data.length; i++) {
    data[i]['a'] = (i+1 - averageAllTimes) * (data[i].immersion - averageAllScores);
    data[i]['b'] = Math.pow((i+1 - averageAllTimes),2);

    if (i<=halfCount) {
      partOneASum += data[i]['a'];
      partOneBSum += data[i]['b'];
    } else {
      partTwoASum += data[i]['a'];
      partTwoBSum += data[i]['b'];
    }
  }

  //console.log(data);

  const sectionOne = (partOneASum/partOneBSum);
  const sectionTwo = (partTwoASum/partTwoBSum);
  const SLOPE_CONSTANT = [-0.02, 0.02];  

  console.log(`RESULT: ${sectionOne}   ${sectionTwo}`);

  if (  ((SLOPE_CONSTANT[0] <= sectionOne) && (sectionOne < SLOPE_CONSTANT[1])) &&
        ((SLOPE_CONSTANT[0] <= sectionTwo) && (sectionTwo < SLOPE_CONSTANT[1]))
      ) {
        //i am flat
        setShape('flat');
        console.log("i am flat");
        myshape = 'flat';

  } else if ( (SLOPE_CONSTANT[0] <= sectionOne) && (sectionTwo >= sectionOne) && (sectionTwo > SLOPE_CONSTANT[1]) ) {
        //i am up/rising
        setShape('up');
        console.log("i am up");
        myshape = 'up';

  } else if ( (sectionOne < SLOPE_CONSTANT[1]) && (sectionTwo <= sectionOne) && (sectionTwo < SLOPE_CONSTANT[0]) ) {
        //i am down/dropping
        setShape('down');
        console.log("i am down");
        myshape = 'down';

  } else if ( (sectionOne > SLOPE_CONSTANT[1]) && (sectionTwo < SLOPE_CONSTANT[1]) ) {
    //i am mountain
    setShape('mtn');
    console.log("i am mtn");
    myshape = 'mtn';

  } else if ( (sectionOne < SLOPE_CONSTANT[0]) && (sectionTwo > SLOPE_CONSTANT[0]) ) {
    //i am valley
    setShape('valley');
    console.log("i am valley");
    myshape = 'valley';

  } else {
    //i am unknown...
    setShape('flat');
    console.log("i am unmatched/flat");
    myshape = 'flat';

  }




} else {
  setShape('solid');
  myshape = 'solid';
}

return myshape;


      }




    return (
        <>


            {  !!immersionOverall && immersionOverall > 0 && completeCount > 0 && hasData && 
            <>

              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>

                    <Grid item xs={12}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        SUMMARY
                    </Typography>

                {immersionOverall && safetyOverall && shareRequest && 
                    <ShareRequestResultsSummary immersion={immersionOverall} safety={safetyOverall} title={shareRequest.title} type={shareRequest.type} attendeeCount={shareRequest.completed_count}/>
                }
                    </Grid>
                    
                </Grid>
              

               


                { isPaid === false && completeCount > 0 && hasData && 
                  <>
                 
                  <hr className={classes.hrMargin}></hr>
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} justifyContent="center">
              <Typography variant={`h3`} color="textPrimary" gutterBottom={false} style={{textAlign: 'center'}} paragraph={true}>
                  Want the full report?
                  </Typography>

                  <Typography variant={`p`} color="textPrimary" gutterBottom={false} paragraph={true} style={{textAlign: 'center'}} >
                  With the full report, you can see a timeline graph by minute of the complete experience, download the data for deeper analysis and break the timeline into 5, 15, 30, or 60 minute segments to get a deep understanding of how your audience responded to this experience.
                  </Typography>

                  <Typography variant={`p`} color="textPrimary" gutterBottom={false}  paragraph={true} style={{textAlign: 'center'}} >
                  Full report is only $10 per completed recipient!
                  </Typography>

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={(event) => buy(shareRequest.completed_count)}>
                      Buy Now
                  </Button>
                  </div>

                </Grid> 

                <Grid item xs={1} ></Grid>
              </Grid>
              <hr className={classes.hrMargin}></hr>
              </>
              }


                <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}} ref={detailRef}>
                  
                <Grid item xs={6}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        DETAIL
                    </Typography>
                </Grid>

                <Grid item xs={6} alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                    <Grid container  alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
                      <Grid item={true} xs={12}  className={classes.actionBar} style={{display: 'grid', justifyContent: "right", justifyItems: "right"}}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  
                          <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('1m')} className={classes.button} disabled={aggWindow === '1h' || isPaid === false}>
                              1m
                          </Button>
                            <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('5m')} className={classes.button} disabled={aggWindow === '5m' || isPaid === false}>
                                5m
                            </Button>
                            <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('15m')} className={classes.button} disabled={aggWindow === '15m' || isPaid === false}>
                                15m
                            </Button>
                            <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('30m')} className={classes.button} disabled={aggWindow === '30m' || isPaid === false}>
                                30m
                            </Button>
                            
                            <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                                <ImageIcon onClick={downloadDetailSection}/> 
                            </Button>
                  
                            <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`SIX_event_export_${aggWindow}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                                <CloudDownloadIcon />
                            </CustomCvsButton>
                          


                        </ButtonGroup>
                      </Grid>
                    </Grid>
                </Grid>
 

              <Grid item xs={12} style={{height: '50vh'}} className={isPaid === true ? classes.noBlur : classes.doBlur}>
{  data && 
                <ResponsiveLine 
  data={data}
  margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
  colors={{ scheme: 'accent' }}
  curve="natural"
  enableArea={true}
  axisBottom={{
    "orient": "bottom",
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 90,
    "format": (d) => {return moment(d).minute() % 3 === 0 ? moment(d).format('h:mm a') : '';},
    "legend": legend,
    "legendOffset": 10,
    "legendPosition": 'middle'
  }}
  
  yScale={{
    type: 'linear',
    min: 0,
    max: 100,
    stacked: false,
    reverse: false
}}
  lineWidth={5}
  pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
  legends={[
    {
        anchor: 'top-left',

        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -30,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
    ]}
/>
  }








                </Grid>
                
                </Grid>


                
                { experienceAccountIds && shareRequest && 
                <ShareRequestResultsTimeline shareRequest={shareRequest} team={team} startRange={startRange} endRange={endRange} accountIds={experienceAccountIds} isPaid={isPaid}/>
                }



                {  experienceAccountIds && shareRequest && 
                  <ShareRequestDemoLive shareRequest={shareRequest} startRange={startRange} endRange={endRange} accountIds={experienceAccountIds} isPaid={isPaid} />
                }




                </>

            }

            { completeCount == 0 && hasData == false && 
              <>
              
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>

                    <Grid item xs={12}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        NO PARTICIPANTS
                    </Typography>

                    <Typography variant={`p`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        Bummer. We can't find anyone that completed this experience. Adjust your Invite title or reward and try sending again.
                    </Typography>

                
                    </Grid>
                    
                </Grid>


              </>
            }

            { completeCount >= 1 && hasData == false && 
              <>
              
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>

                    <Grid item xs={12}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        LOADING DATA...
                    </Typography>

                    <Typography variant={`p`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        Please be patient. Your data is loading....
                    </Typography>

                
                    </Grid>
                    
                </Grid>


              </>
            }




    <Dialog
        open={nobuyopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"No completed participants"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There were no completed participants for this experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

        </>
    )


};

