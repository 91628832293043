import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveStream } from '@nivo/stream'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { sendNotificationToClient } from './../util/util'
import { getAccount } from "../util/db";
//const moment = require('moment');

import SendNotificationDialog from "./SendNotificationDialog";


//console.log(process.env.REACT_APP_INFLUX_URL);

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;


const useStyles = makeStyles((theme) => ({
  white: {
    background: '#fff',
  },
}));


export const ScatterChart = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { startRange, aggWindow, groupId, legend, downloadCallback, ...otherProps } = props;
  const [showMessagingWindow, setShowMessagingWindow] = useState(false);
  const [activeMessagingUser, setActiveMessagingUser] = useState({});
  
  let query = `from(bucket: "${bucket}")
  |> range(start: ${startRange})
  |> filter(fn: (r) => r["_measurement"] == "score")
  |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
  |> filter(fn: (r) => r["gid"] == "${groupId}")
  |> group(columns: ["_measurement", "_field", "gid", "pid"])
  |> aggregateWindow(every: ${aggWindow}, fn: mean, createEmpty: false)
  |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
  |> yield(name: "mean")`;


  //console.log(query);

  useEffect(() => {
    const interval = setInterval(() => {
      loadPageData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);




  useEffect(() => {
    loadPageData();
  }, [startRange]);


  const loadPageData = () => {
    console.log("loadPageData");

    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
      //console.log(queryApi);

      //make query
      await queryApi.queryRows(query, {
        next(row, tableMeta) {
           // console.log("a");
          const o = tableMeta.toObject(row);
         //push rows from query into an array object
          res.push(o);
        },
        complete() {
          
          let finalData = []
          let tmpDownloadData = []

          //console.log(res);
          //variable is used to track if the current ID already has a key
          

          for(let i = 0; i < res.length; i++) {
            //let d = moment(new Date(res[i]['_time'])).format("YYYY-MM-DD HH:mm:ss")
            //let jd = new Date(res[i]['_time'])
  
            let item = {"id": res[i]['pid'], "data": [{ "y": res[i]['e'], "x" : res[i]['s'] }]};

            var foundMatch = false;
            for(let z = 0; z < finalData.length; z++) {
              if (finalData[z]["id"] == item.id ) {
                foundMatch = true;
                //console.log("foundMatch = " + foundMatch)
                finalData[z] = item;
              }
            }

            if (!foundMatch) {
              finalData.push(item);

              tmpDownloadData.push({"date": res[i]['_time'], "pid": res[i]['pid'], "immersion": res[i]['e'], "safety" : res[i]['s'] });

            }

            
          }

          //finalData.push({"id": "Engagement", "data" : engagement});
          //finalData.push({"id": "Safety", "data" : safety});

       //   console.log("Final Data: ");
        //  console.log(finalData);

          setData(finalData);
          downloadCallback(tmpDownloadData, startRange);
      
        },
        error(error) {
          console.log("query failed- ", error);
        }
      });
     
    };

    influxQuery();
  }


  const showMessaging = (node) => {

    const pid = node.serieId;
    
   // sendNotificationToClient(["em8EZa0hOEAWtRBjlLo4l2:APA91bFnmOwR-O_jCNM5Rw01kTspCZBbon9jOMLhJPZN1fwxSlmKFdRBMJDBSJjvCLLd25FXEwI_vpzhAmsAvFIwmQGRm_Zat0wllvvzvYaLJnE59zFpws39HwXKdj1jvrenAu7JhfpG"], {body: "foo", title: "bar"});

//ZV4abp1q4BTn6cDBY1EbFiw9yOQ2
//em8EZa0hOEAWtRBjlLo4l2:APA91bFnmOwR-O_jCNM5Rw01kTspCZBbon9jOMLhJPZN1fwxSlmKFdRBMJDBSJjvCLLd25FXEwI_vpzhAmsAvFIwmQGRm_Zat0wllvvzvYaLJnE59zFpws39HwXKdj1jvrenAu7JhfpG


    setActiveMessagingUser({pid: pid});

    setShowMessagingWindow(true);
    console.log(node);
  }

  const handleClickOpen = () => {
    setShowMessagingWindow(true);
  };

  const handleClose = () => {
    setActiveMessagingUser({});
    setShowMessagingWindow(false);
  };

  const handleSaveItem = async (data) => {

    const account = await getAccount(data.pid);
    //console.log(account);
    if (!!account) {
      const fcmToken = account.fcm_token;
      if (!!fcmToken) {
        const obMessage = {title: data.title, message: data.message, fcm_token: fcmToken};
        console.log(obMessage);
        await sendNotificationFetch(obMessage);
      }

    }

/*
    getAccount(data.pid).then(account) {
      console.log(account);
      const fcmToken = account.fcm_token;
      if (!!fcmToken) {
        const obMessage = {title: data.title, message: data.message, fcm_token: fcmToken};
        console.log(obMessage);
      }
    };
*/


    setActiveMessagingUser({});
    //console.log("Saved data:", data);
  }


  const sendNotificationFetch = async (data) => {
    const url = 'https://sendnotification-qfuz77ppga-uc.a.run.app';

    return await fetch(url, {
    
      // Adding method type
      method: "POST",
      
      // Adding body or contents to send
      body: JSON.stringify(data),
      
      // Adding headers to the request
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
  })
  
  // Converting to JSON
  .then(response => response.json())
  
  // Displaying results to console
  .then(json => {
      //console.log(json);
      return json.url;
  });

  }


  return (

<>

  <ResponsiveScatterPlot 
  className={classes.white}
  data={data}
  margin={{ top: 50, right: 110, bottom: 100, left: 80 }}
  xScale={{ type: 'linear', min: 0, max: 100 }}
  xFormat=">-.0f"
  yScale={{ type: 'linear', min: 0, max: 100 }}
  yFormat=">-.0f"
  colors={{ scheme: 'accent' }}
  gridXValues={[0,50,100]}
  gridYValues={[0,50,100]}
  nodeSize={20}
  onClick={node => (
    showMessaging(node)
  )}
  tooltip={({ node }) => (
                <div
                    style={{
                        color: '#fff',
                        background: '#062A53',
                        padding: '12px 16px',
                    }}
                >
                    <strong>
                        {node.serieId}
                    </strong>
                    <br />
                    {`Value: ${node.formattedY}`}
                    <br />
                    {`Safety: ${node.formattedX}`}
                </div>
            )}
  axisBottom={{
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'PSYCHOLOGICAL SAFETY',
    legendPosition: 'middle',
    legendOffset: 46
}}
axisLeft={{
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'VALUE SCORE',
    legendPosition: 'middle',
    legendOffset: -60
}}
  legends={[
    {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 130,
        translateY: 0,
        itemWidth: 100,
        itemHeight: 12,
        itemsSpacing: 5,
        itemDirection: 'left-to-right',
        symbolSize: 12,
        symbolShape: 'circle',
        effects: [
            {
                on: 'hover',
                style: {
                    itemOpacity: 1
                }
            }
        ]
    }
]}
/>


{ /** 
      <Dialog
        open={showMessagingWindow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Send message to this participant?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This section would allow you to send a quick note/reminder to this person in an anonymous way.
            As the admin, you will never know the true identity of this person, but you can send them some words of encouragement or point them to an intervention.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Ok
          </Button>
          
        </DialogActions>
      </Dialog>
*/}

      {showMessagingWindow && (
        <SendNotificationDialog
                key={JSON.stringify(activeMessagingUser)}
                open={showMessagingWindow}
                onClose={handleClose}
                onSave={handleSaveItem}
                initialData={activeMessagingUser} // Pass initial data for editing if necessary
            />
        )}


    </>




  )
};