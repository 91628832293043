import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "../util/auth";
import { useTeam, updateTeam } from "../util/db";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";


//process.env.REACT_APP_FIREBASE_API_KEY




function SettingsTeam(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();
  
 



  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    //console.log(data);

    return updateTeam(team.id, { name: data.name, tos: data.terms } )
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your team has been updated",
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  const handleCopyLink = () => {
    
    //const link = `https://wondr.cx/join/${team.teamid}`;
    if (team && team.team_join_link) {
      console.log(`copy to paste buffer: ${team.team_join_link}`);
      navigator.clipboard.writeText(team.team_join_link);
      setFormAlert({
        type: "success",
        message: "Copied link to the clipboard",
      });
    }
  }

  const handleCopyCode = () => {
    const code = `${team.teamid}`;
    console.log(`copy to paste buffer: ${code}`);

    navigator.clipboard.writeText(code);
    setFormAlert({
      type: "success",
      message: "Copied code to the clipboard",
    });

  }



  return (

    
      <form onSubmit={(e) => {handleSubmit(onSubmit)(e);}}>



      {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

{ team &&  


      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Team Name"
            name="name"
            placeholder="Team Name"
            defaultValue={team.name}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your team name",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Team Terms & Conditions"
            name="terms"
            placeholder="https://"
            defaultValue={team.tos}
            error={errors.tos ? true : false}
            helperText={errors.tos && errors.tos.message}
            fullWidth={true}
            inputRef={register({
              
            })}
          />
        </Grid>
        <Grid item={true} xs={10}>
          <TextField
            disabled
            variant="filled"
            type="text"
            label="Team Link"
            name="join_link"
            placeholder=""
            defaultValue={team.team_join_link}
            error={errors.join_link ? true : false}
            helperText={errors.join_link && errors.join_link.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <Button variant="default" color="secondary" size="large" onClick={handleCopyLink} >
              <FileCopyIcon />
          </Button>
        </Grid>

        <Grid item={true} xs={10}>
          <TextField
            disabled
            variant="filled"
            type="text"
            label="Team Code"
            name="teamid"
            placeholder=""
            defaultValue={team.teamid}
            error={errors.teamid ? true : false}
            helperText={errors.teamid && errors.teamid.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <Button variant="default" color="secondary" size="large" onClick={handleCopyCode}>
              <FileCopyIcon />
          </Button>
        </Grid>    


        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={false}
          >
            {!pending && <span>Save</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>

          }

    </form>

  


  );
}

export default SettingsTeam;
