import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@material-ui/core';
import { Description } from '@material-ui/icons';

function SettingsAddEditInterventionDialog({ open, onClose, onSave, initialData = {} }) {
    // State to manage form inputs
    const [formData, setFormData] = useState({
        id: initialData.id || '',
        name: initialData.name || '',
        link: initialData.link || '',
        desc: initialData.desc || '',
        type: initialData.type || '',
        reason: initialData.reason || '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle Save button click
    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{initialData.name ? 'Edit Intervention' : 'Create Intervention'}</DialogTitle>
            <DialogContent>
            <TextField
                    label="Type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    select
                >
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="link">Link</MenuItem>
                    <MenuItem value="website">Website</MenuItem>
                    <MenuItem value="audio">Audio</MenuItem>
                    <MenuItem value="app">App</MenuItem>
                    {/* Add more types as needed */}
                </TextField>
                <TextField
                    label="Reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    select
                >
                    <MenuItem value="hihs">High Value, High Safety</MenuItem>
                    <MenuItem value="hils">High Value, Low Safety</MenuItem>
                    <MenuItem value="lihs">Low Value High Safety</MenuItem>
                    <MenuItem value="lils">Low Value, Low Safety</MenuItem>
                    {/* Add more types as needed */}
                </TextField>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Link"
                    name="link"
                    value={formData.link}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    name="desc"
                    value={formData.desc}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SettingsAddEditInterventionDialog;
