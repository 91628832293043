import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@material-ui/core';
import { Description } from '@material-ui/icons';

function SendNotificationDialog({ open, onClose, onSave, initialData = {} }) {
    // State to manage form inputs
    const [formData, setFormData] = useState({
        pid: initialData.pid || '',
        message: initialData.message || '',
        title: initialData.title || 'A note from SIX',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle Save button click
    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Send message to this participant?</DialogTitle>
            <DialogContent>
            
               
                <TextField
                    label="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
               
                <TextField
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    required
                />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendNotificationDialog;
